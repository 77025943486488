var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vs-prompt",
    {
      attrs: {
        "accept-text": "Kaydet",
        "cancel-text": "Vazgeç",
        active: _vm.active,
        title: "Oyları Düzenle"
      },
      on: {
        cancel: _vm.close,
        accept: _vm.submit,
        close: _vm.close,
        "update:active": function($event) {
          _vm.active = $event
        }
      }
    },
    [
      _c("div", { staticClass: "flexd" }, [
        _c(
          "div",
          { staticClass: "flex items-center" },
          [
            _c("label", { staticClass: "w-32", attrs: { for: "up" } }, [
              _vm._v("Like Sayısı")
            ]),
            _c("vs-input", {
              attrs: { id: "up", required: "", min: "0", type: "number" },
              model: {
                value: _vm.upCount,
                callback: function($$v) {
                  _vm.upCount = $$v
                },
                expression: "upCount"
              }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "mt-4 flex items-center" },
          [
            _c("label", { staticClass: "w-32", attrs: { for: "down" } }, [
              _vm._v("Dislike Sayısı")
            ]),
            _c("vs-input", {
              attrs: { id: "down", required: "", min: "0", type: "number" },
              model: {
                value: _vm.downCount,
                callback: function($$v) {
                  _vm.downCount = $$v
                },
                expression: "downCount"
              }
            })
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }