var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vs-popup",
    {
      staticClass: "fs-popup",
      attrs: {
        "background-color": "rgba(0,0,0,1)",
        "background-color-popup": "rgba(0,0,0,.5)",
        fullscreen: "",
        title: "",
        active: _vm.active
      },
      on: {
        "update:active": function($event) {
          _vm.active = $event
        },
        close: _vm.close
      }
    },
    [
      _c(
        "swiper",
        {
          key: "images",
          ref: "swiperTop",
          staticClass: "gallery-top",
          attrs: {
            options: _vm.swiperOptionTop,
            dir: _vm.$vs.rtl ? "rtl" : "ltr"
          }
        },
        [
          _vm._l(_vm.images, function(image) {
            return _c("swiper-slide", { key: image }, [
              _c("img", {
                staticClass: "responsive swiper-image",
                attrs: { src: _vm.getImageUrl(image), alt: "banner" }
              })
            ])
          }),
          _c("div", {
            staticClass: "swiper-button-next swiper-button-white",
            attrs: { slot: "button-next" },
            slot: "button-next"
          }),
          _c("div", {
            staticClass: "swiper-button-prev swiper-button-white",
            attrs: { slot: "button-prev" },
            slot: "button-prev"
          })
        ],
        2
      ),
      _c(
        "div",
        {
          staticClass: "swiper-thumbnails px-4 overflow-auto flex items-start"
        },
        [
          _c(
            "div",
            {
              staticClass:
                "swiper-thumbnails-wrapper flex justify-center  mx-auto py-6"
            },
            _vm._l(_vm.images, function(image, index) {
              return _c(
                "div",
                {
                  key: image,
                  staticClass: "swiper-thumb-wrapper",
                  on: {
                    click: function($event) {
                      return _vm.toSlide(index)
                    }
                  }
                },
                [
                  _c("img", {
                    staticClass: "responsive swiper-thumbs",
                    attrs: {
                      src: _vm.getImageUrl(image, "75x0"),
                      alt: "banner"
                    }
                  })
                ]
              )
            }),
            0
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }