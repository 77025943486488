<template>
  <vs-prompt
    @cancel="close"
    @accept="submit"
    accept-text="Kaydet"
    cancel-text="Vazgeç"
    @close="close"
    :active.sync="active"
    title="Oyları Düzenle"
  >
    <div class="flexd">
      <div class="flex items-center">
        <label for="up" class="w-32">Like Sayısı</label>
        <vs-input id="up" required min="0" type="number" v-model="upCount" />
      </div>
      <div class="mt-4 flex items-center">
        <label for="down" class="w-32">Dislike Sayısı</label>
        <vs-input id="down" required min="0" type="number" v-model="downCount" />
      </div>
    </div>
  </vs-prompt>
</template>

<script>
import * as _ from 'lodash-es';
import EntryService from '@/services/EntryService';
export default {
  name: 'EntryVotePopup',
  props: ['isActive', 'entry'],
  data() {
    return {
      active: false,
      upCount: null,
      downCount: null,
      entryData: {}
    };
  },
  computed: {},
  methods: {
    init() {
      this.upCount = this.entryData.voteCount.upCount;
      this.downCount = this.entryData.voteCount.downCount;
    },
    close() {
      this.$emit('close', false);
    },
    async submit() {
      if (confirm('Dio oylarını güncellemek istediğinize emin misiniz?')) {
        try {
          let payload = {
            id: this.entryData.id,
            like: this.upCount,
            dislike: this.downCount
          };
          await EntryService.vote(payload);
          this.$vs.notify({
            color: 'success',
            text: 'Dio başarıyla güncellendi.'
          });
          this.$emit('close', false);
        } catch (e) {
          this.$vs.notify({
            color: 'danger',
            text: 'Bir hata oluştu.'
          });
        }
      }
    }
  },
  watch: {
    isActive(val) {
      this.active = val;
    },
    entry(val) {
      this.entryData = _.cloneDeep(val);

      this.init();
    }
  }
};
</script>
