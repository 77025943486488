<template>
  <vs-popup
    background-color="rgba(0,0,0,1)"
    background-color-popup="rgba(0,0,0,.5)"
    fullscreen
    class="fs-popup"
    title=""
    :active.sync="active"
    @close="close"
  >
    <swiper :options="swiperOptionTop" class="gallery-top" ref="swiperTop" :dir="$vs.rtl ? 'rtl' : 'ltr'" :key="'images'">
      <swiper-slide v-for="image in images" :key="image">
        <img class="responsive swiper-image" :src="getImageUrl(image)" alt="banner" />
      </swiper-slide>
      <div class="swiper-button-next swiper-button-white" slot="button-next"></div>
      <div class="swiper-button-prev swiper-button-white" slot="button-prev"></div>
    </swiper>

    <div class="swiper-thumbnails px-4 overflow-auto flex items-start">
      <div class="swiper-thumbnails-wrapper flex justify-center  mx-auto py-6">
        <div class="swiper-thumb-wrapper" v-for="(image, index) in images" :key="image" @click="toSlide(index)">
          <img class="responsive swiper-thumbs" :src="getImageUrl(image, '75x0')" alt="banner" />
        </div>
      </div>
    </div>
  </vs-popup>
</template>

<script>
import 'swiper/dist/css/swiper.min.css';
import { swiper, swiperSlide } from 'vue-awesome-swiper';
import { generateImageUrl, getFileExtension } from '@/utils/imageHash';
export default {
  name: 'TopicUpdate',
  props: ['isActive', 'images', 'selectedImage'],
  data() {
    return {
      active: false,
      swiperOptionTop: {
        spaceBetween: 10,
        centeredSlides: true,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      },
      swiperOptionThumbs: {
        spaceBetween: 10,
        centeredSlides: true,
        slidesPerView: 'auto',
        touchRatio: 0.2,
        slideToClickedSlide: true
      }
    };
  },
  components: {
    swiper,
    swiperSlide
  },

  methods: {
    toSlide(i) {
      this.$refs.swiperTop.swiper.slideTo(i, 0);
    },
    getImageUrl(image, size = '1024x0') {
      let params = {
        size: size,
        type: getFileExtension(image),
        quality: '80',
        imageUrl: image
      };
      var newUrl = generateImageUrl(params);
      return newUrl;
    },
    close() {
      this.$emit('close', false);
    }
  },
  watch: {
    isActive(val) {
      if (val) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = '';
      }
      this.active = val;
    },
    selectedImage(val) {
      if (!this.images) return;
      const index = this.images.findIndex((img) => img === val);
      this.toSlide(index);
    }
  }
};
</script>

<style lang="scss">
.fs-popup {
  .swiper-container {
    background-color: #000;
  }
  .gallery-top {
    height: calc(100% - 120px);
    width: 100%;
  }
  .swiper-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  .vs-popup--content {
    height: 100% !important;
    padding: 0;
  }
  .vs-popup--close--icon {
    color: #ccc;
  }
  .swiper-thumb-wrapper {
    max-height: 75px;
    width: 100px;
    background: #000;
    margin-right: 15px;
    border: 1px solid #ccc;
    flex: none;
  }
  .swiper-thumbs {
    height: 100%;
    object-fit: contain;
  }
  .swiper-thumbnails {
    background: #000;
  }
}
</style>
