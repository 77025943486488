<template>
  <div>
    <vs-table stripe :multiple="false" v-model="selected" :sst="true" :max-items="50" :data="list" noDataText="">
      <div class="flex items-center justify-between w-full" slot="header">
        <div v-if="$store.state.route.query.search">
          <h3>aranan: {{ $store.state.route.query.search }}</h3>
        </div>
        <div v-if="topic">
          <h3 class="text-lg max-w-md font-bold">
            {{ topic.topic }}
          </h3>
        </div>
        <div class="con-input-search vs-table--search">
          <input v-model="search" maxlength="50" v-on:keyup.enter="handleSearch" class="input-search vs-table--search-input" type="text" />
          <vs-icon icon="search"></vs-icon>
        </div>
      </div>
      <template slot="thead">
        <vs-th sort-key="order_status">Durum</vs-th>
        <vs-th sort-key="topic">Diolar</vs-th>
        <vs-th sort-key="username">Kullanıcı</vs-th>
        <vs-th sort-key="createDate" @click.native="handleSort('createDate', 'asc')">
          Oluşturulma Tarihi
          <span v-if="$store.state.route.query.orderKey === 'createDate' && $store.state.route.query.orderValue === 'asc'">
            <vs-icon icon="expand_less"></vs-icon>
          </span>
          <span v-if="$store.state.route.query.orderKey === 'createDate' && $store.state.route.query.orderValue === 'desc'">
            <vs-icon icon="expand_more"></vs-icon>
          </span>
        </vs-th>
        <vs-th sort-key="updateDate" @click.native="handleSort('updateDate', 'desc')">
          Güncellenme Tarihi
          <span v-if="$store.state.route.query.orderKey === 'updateDate' && $store.state.route.query.orderValue === 'asc'">
            <vs-icon icon="expand_less"></vs-icon>
          </span>
          <span v-if="$store.state.route.query.orderKey === 'updateDate' && $store.state.route.query.orderValue === 'desc'">
            <vs-icon icon="expand_more"></vs-icon>
          </span>
        </vs-th>
        <vs-th sort-key="publishDate" v-if="false">Yayınlanma Tarihi</vs-th>
        <vs-th></vs-th>
      </template>
      <template slot-scope="{ data }">
        <tbody>
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td id="status">
              <div class="flex flex-wrap w-16">
                <div class="col w-1/2 bg-gray-400x text-gray-500 h-8 flex items-center justify-center">
                  <vx-tooltip :text="states[tr.state].text" class="state-tooltip">
                    <div
                      class="w-5 h-5 text-sm rounded-full text-white font-bold flex items-center justify-center leading-none"
                      :class="states[tr.state].color"
                    >
                      {{ states[tr.state].shortText }}
                    </div>
                  </vx-tooltip>
                </div>
              </div>
            </vs-td>

            <vs-td id="dio" class="dio__text max-w-sm overflow-auto min-w-64">
              <div id="dio-title" v-if="!topic && tr.topic">
                <strong id="entrytopic">
                  {{ tr.topic.topic }}
                </strong>
              </div>
              <div id="entryhtml" class="mt-3 whitespace-pre-wrap " v-text="tr.entry.entryData"></div>
              <div class="flex break-word my-2" v-if="tr.entry.entryImageUrls && tr.entry.entryImageUrls.length">
                <div v-for="(img, index) in tr.entry.entryImageUrls" :key="index" class="w-1/5">
                  <img
                    @click="openImagePopup(tr.entry.entryImageUrls, img)"
                    :src="getImageUrl(img, '75x75')"
                    class="rounded image-entry pl-0 p-2"
                  />
                </div>
              </div>
            </vs-td>

            <vs-td id="username" class="max-w-64 min-w-32 break-word">
              {{ tr.user.username }}
            </vs-td>

            <vs-td id="createDate">
              <p class="w-24 font-medium">
                {{ tr.createDate | moment('DD-MM-YYYY') }}
                <br />
                {{ tr.createDate | moment('HH:mm') }}
              </p>
            </vs-td>

            <vs-td id="updateDate">
              <p class="w-24 font-medium">
                {{ tr.updateDate | moment('DD-MM-YYYY') }}
                <br />
                {{ tr.updateDate | moment('HH:mm') }}
              </p>
            </vs-td>

            <vs-td id="publishDate" v-if="false">
              <p class="w-24 font-medium" v-if="tr.publishDate">
                {{ tr.publishDate | moment('DD-MM-YYYY') }}
                <br />
                {{ tr.publishDate | moment('HH:mm') }}
              </p>
              <p>-</p>
            </vs-td>

            <vs-td>
              <div class="flex items-center space-x-2 justify-end">
                <div class="flex space-x-1">
                  <vx-tooltip text="Düzenle">
                    <vs-button
                      @click="editEntry"
                      color="secondary"
                      size="small"
                      class="btn btn--action"
                      type="gradient"
                      icon="edit"
                    ></vs-button>
                  </vx-tooltip>

                  <vx-tooltip text="Geri Getir" v-if="tr.state.includes('deleted')">
                    <vs-button
                      @click="restoreEntryById($event, tr.id)"
                      size="small"
                      class="btn btn--action"
                      type="gradient"
                      color="warning"
                      icon="settings_backup_restore"
                    ></vs-button>
                  </vx-tooltip>

                  <vx-tooltip text="Sil" v-if="!tr.state.includes('deleted')">
                    <vs-button
                      size="small"
                      class="btn btn--action"
                      @click="(complaintMessageDialog = true), deleteEntryById($event, tr.topic, tr.id, tr.userId, tr.entry.entryData)"
                      vs-type="flat"
                      color="danger"
                      icon="delete"
                    ></vs-button>
                  </vx-tooltip>
                </div>
                <vs-dropdown
                  vs-trigger-click
                  class="action-button action-button--topic cursor-pointer rounded border border-solid border-gray-300 bg-gray-100 hover:bg-gray-300 px-1"
                >
                  <div class="flex items-center">
                    <feather-icon icon="MoreHorizontalIcon" svgClasses="more-icon" />
                  </div>

                  <vs-dropdown-menu class="w-56">
                    <vs-dropdown-item @click="voteEntry">
                      <span class="flex items-center text-base">
                        <div class="-ml-3 flex items-center justify-center w-10">
                          <vs-icon icon="icon-heart" icon-pack="feather" />
                        </div>
                        <span>Oyları Düzenle</span>
                      </span>
                    </vs-dropdown-item>
                    <vs-dropdown-item @click="showSendMessageDialog = true">
                      <span class="flex items-center text-base">
                        <div class="-ml-3 flex items-center justify-center w-10">
                          <vs-icon icon="icon-send" icon-pack="feather" />
                        </div>
                        <span>Mesaj Gönder</span>
                      </span>
                    </vs-dropdown-item>
                  </vs-dropdown-menu>
                </vs-dropdown>
              </div>
            </vs-td>
          </vs-tr>
        </tbody>
      </template>
    </vs-table>

    <popup-update-entry :is-active="showUpdateDialog" @close="showUpdateDialog = false" :entry="selected"></popup-update-entry>
    <popup-image
      :is-active="showImageDialog"
      @close="showImageDialog = false"
      :images="selectedImages"
      :selected-image="selectedImage"
    ></popup-image>
    <popup-entry-vote :is-active="showVoteDialog" @close="showVoteDialog = false" :entry="selected"></popup-entry-vote>
    <popup-send-message :user="selected.user" :is-active="showSendMessageDialog" @close="showSendMessageDialog = false" />
    <popup-complaint-send-message
      :user="selected.user"
      :is-active="complaintMessageDialog"
      @close="complaintMessageDialog = false"
      :topic="topic"
      :id="id"
      :userId="userId"
      :tab="tab"
      :entry="entry"
    />
  </div>
</template>

<script>
import PopupImage from '@/components/common/image/PopupImage.vue';
import PopupUpdateEntry from '@/components/entry/PopupUpdateEntry.vue';
import PopupEntryVote from '@/components/popups/PopupEntryVote.vue';
import PopupSendMessage from '@/components/popups/PopupSendMessage.vue';
import { generateImageUrl } from '@/utils/imageHash';
import { useQueryParams } from '@/mixins';
import PopupComplaintSendMessage from '@/components/popups/PopupComplaintSendMessage';
import { mapActions } from 'vuex';
export default {
  props: ['list', 'topic'],
  components: { PopupUpdateEntry, PopupImage, PopupEntryVote, PopupSendMessage, PopupComplaintSendMessage },
  mixins: [useQueryParams],
  data: () => ({
    search: '',
    selected: [],
    selectedImage: null,
    selectedImages: [],
    showUpdateDialog: false,
    showImageDialog: false,
    showVoteDialog: false,
    showSendMessageDialog: false,
    id: '',
    complaintMessageDialog: false,
    userId: '',
    tab: 'entry',
    entry: '',
    states: {
      deleted: {
        color: 'bg-red-500',
        shortText: 'S',
        text: 'Silinmiş'
      },
      waiting: {
        color: 'bg-orange-500',
        shortText: 'B',
        text: 'Bekleyen'
      },
      approved: {
        color: 'bg-green-500',
        shortText: 'O',
        text: 'Onaylanmış'
      },
      restored: {
        color: 'bg-gray-500',
        shortText: 'GG',
        text: 'Geri Getirilmiş'
      }
    }
  }),
  created() {
    this.search = this.$store.state.route.query.search || '';
  },
  watch: {
    search(val) {
      const query = this.$store.state.route.query;
      if (val.length === 0 && query.hasOwnProperty('search')) {
        delete query.search;
        this.$router.replace({ query: { ...query, page: 1, tab: 'all' } });
      }
    }
  },
  methods: {
    ...mapActions('complaint', { getList: 'getFilterList' }),

    openImagePopup(imageUrls, selectedImage) {
      this.selectedImage = selectedImage;
      this.selectedImages = imageUrls;
      this.showImageDialog = true;
    },
    deleteEntryById($event, topic, id, userId, entry) {
      this.id = id;
      this.userId = userId;
      this.entry = entry;
      $event.stopPropagation();
      this.$emit('refreshData', this.entryType);
    },
    restoreEntryById($event, id) {
      $event.stopPropagation();
      const payload = { id };
      this.$emit('action', { action: 'restoreEntry', payload });
    },
    handleSearch() {
      this.$emit('search', { text: this.search });
    },

    getImageUrl(image, size = '300x0') {
      let params = {
        size: size,
        quality: '80',
        imageUrl: image,
        gifToImage: true
      };
      var newUrl = generateImageUrl(params);
      return newUrl;
    },
    editEntry() {
      this.showUpdateDialog = true;
    },
    voteEntry() {
      this.showVoteDialog = true;
    },
    handleSort(orderKey, _orderState) {
      // todo: null state fix
      const currentQuery = this.$route.query;
      let nextValue = 'asc';

      if (currentQuery.orderValue === 'asc') {
        nextValue = 'desc';
      }
      if (currentQuery.orderValue === 'desc') {
        nextValue = null;
      }
      // order key eşit değilse sıfırla
      if (currentQuery.orderKey !== orderKey) {
        nextValue = 'asc';
      }

      if (!nextValue) {
        this.changeRoute({ orderKey: null, orderValue: null }); // null verirsen null verdiklerini siler
      } else {
        this.changeRoute({ orderKey, orderValue: nextValue });
      }
    },
    changeRoute(query, { reset, replace } = {}) {
      if (reset) {
        this.resetQueryParams();
      }
      const newPath = { query: { ...this.$query, ...query } };
      let cleanQuery = { query: {} };
      //null ise sil
      Object.keys(newPath.query).forEach(function(key) {
        if (newPath.query[key] !== null) {
          cleanQuery.query[key] = newPath.query[key];
        }
      });
      // router.push ya da router.replace
      const method = replace ? 'replace' : 'push';
      this.$router[method](cleanQuery);
    }
  }
};
</script>
<style lang="scss">
.vs-dropdown--menu--after {
  display: none;
}
.more-icon {
  width: 24px;
  height: 24px;
}
.vs-dropdown--item-link {
  .feather {
    font-size: 1.2rem;
  }
}
.state-tooltip {
  display: flex;
  align-items: center;
  justify-content: center;
}
.sort-th {
  i {
    display: none !important;
  }
}
</style>
