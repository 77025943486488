<template>
  <vs-popup title="Dio güncelle" :active.sync="active" @close="close" v-if="active">
    <div v-if="entryData.entry">
      <div>
        <vs-textarea counter="3000" :counter-danger.sync="counterDanger" v-model="entryData.entry.entryData" height="300px" />
      </div>
      <div class="image-upload my-6 flex flex-shrink-0 ">
        <div class="px-4 h-24 my-2 flex items-center">
          <div class=" pt-2 px-2 bg-red-600 rounded-lg inline-block">
            <label for="image-upload" class="cursor-pointer text-white " click="buttonAction">
              <vs-icon class="" icon="image" size="medium" bg="none"></vs-icon>
            </label>
          </div>
          <input id="image-upload" class="hidden" type="file" ref="myFiles" @change="handleImageAdd" accept="image/*" multiple />
        </div>
        <div class="flex flex-1 w-full flex-wrap" v-if="uploadImages.length">
          <div v-for="img in uploadImages" :key="img.id" class="w-1/5 bg-gray-500x h-24 my-2 relative">
            <img :src="img.url" class="rounded responsıve h-full p-2 mx-2 bg-gray-800x" />
            <vs-button
              @click="deleteImage(img, img.id)"
              color="dark"
              title="Resmi Sil"
              class="absolute top-0 right-0 z-sm"
              type="gradient"
              size="small"
              icon="close"
              radius
            ></vs-button>
          </div>
        </div>
      </div>

      <div class="action-buttons text-right mt-2">
        <vs-button type="flat" @click="close">
          Vazgeç
        </vs-button>
        <vs-button class="ml-4" @click="submit">
          Güncelle
        </vs-button>
      </div>
    </div>
  </vs-popup>
</template>

<script>
import { mapActions } from 'vuex';
import * as _ from 'lodash-es';
import { generateImageUrl } from '@/utils/imageHash';
import UploadService from '@/services/UploadService';
export default {
  name: 'EntryUpdate',
  props: ['isActive', 'entry'],
  data() {
    return {
      active: false,
      entryData: {},
      counterDanger: false,
      uploadImages: []
    };
  },
  methods: {
    ...mapActions('entry', ['updateEntry']),
    close() {
      this.$emit('close', false);
    },
    init() {
      this.uploadImages = [];
      if (!this.entryData.entry.entryImageUrls) return;
      this.entryData.entry.entryImageUrls.forEach((item) => {
        this.newImage({
          url: this.getImageUrl(item, '75x75'),
          source: item
        });
      });
    },
    handleImageAdd() {
      let files = this.$refs.myFiles.files;
      for (let index = 0; index < files.length; index++) {
        const image = files[index];
        this.newImage({
          url: typeof file == 'string' ? image : URL.createObjectURL(image),
          source: null,
          image
        });
      }
      this.upload();
    },
    newImage({ url, source, ...rest }) {
      this.uploadImages.push({ id: _.uniqueId(), url, source, ...rest });
    },
    async upload() {
      await this.asyncForEach(this.uploadImages, async (item) => {
        if (item.source) {
          return;
        }
        if (item.image.type.indexOf('image') == -1) {
          return;
        }
        let formData = new FormData();
        formData.append('image', item.image);
        const upload = await UploadService.upload(formData);
        item.source = upload.urlEncodedFileName;
      });
    },

    async asyncForEach(array, callback) {
      for (let index = 0; index < array.length; index++) {
        await callback(array[index], index, array);
      }
    },
    getImageUrl(image, size = '300x0') {
      let params = {
        size: size,
        type: 'png',
        quality: '80',
        imageUrl: image
      };
      var newUrl = generateImageUrl(params);
      return newUrl;
    },
    deleteImage(image, id) {
      this.uploadImages = this.uploadImages.filter((item) => item.id !== id);
    },
    async submit() {
      if (confirm("Dio'yu güncellemek istedeğinize emin misiniz?")) {
        try {
          const entryImageUrls = this.uploadImages.map((item) => {
            return item.source;
          });
          this.entryData.entry.entryImageUrls = entryImageUrls;

          let payload = {
            entry: this.entryData
          };
          await this.updateEntry(payload);
          this.$vs.notify({
            color: 'success',
            text: 'Başarıyla güncellendi.'
          });
          this.$emit('close', false);
        } catch (e) {
          this.$vs.notify({
            color: 'danger',
            text: 'Dio güncellenemedi.'
          });
        }
      }
    }
  },
  watch: {
    isActive(val) {
      this.active = val;
    },
    entry(val) {
      this.entryData = _.cloneDeep(val);
      this.init(); // popup zaten mounted oldugu icin
    }
  }
};
</script>
<style>
.vs-textarea {
  height: 100% !important;
}
.vs-tooltip {
  z-index: 55000;
}
</style>
