<template>
  <div>
    <vs-button
      v-bind:class="{ active: $store.state.route.query.tab === 'all' }"
      color="primary"
      :type="$store.state.route.query.tab === 'all' ? 'filled' : 'line'"
      @click="handleTabClick('all')"
      class="tab-btn"
    >
      Tüm Diolar
      <span class="font-medium" v-if="total && $query.tab === 'all'">({{ total }})</span>
    </vs-button>
    <vs-button
      v-bind:class="{ active: $store.state.route.query.tab === 'deleted' }"
      color="danger"
      :type="$store.state.route.query.tab === 'deleted' ? 'filled' : 'line'"
      @click="handleTabClick('deleted')"
      class="tab-btn"
    >
      Silinenler
      <span class="font-medium" v-if="total && $query.tab === 'deleted'">({{ total }})</span>
    </vs-button>
    <entry-list :list="list" :topic="topic" @search="handleSearch" @action="handleActions"></entry-list>
    <div class="con-pagination-table vs-table--pagination">
      <vs-pagination :total="getTotalPages" v-model="page"></vs-pagination>
    </div>
  </div>
</template>

<script>
import EntryList from '@/components/entry/EntryList.vue';
import { usePagination, useQueryParams, usePageRefresh } from '@/mixins';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import _ from 'lodash';
export default {
  components: { EntryList },
  mixins: [usePagination({ moduleName: 'entry' }), useQueryParams, usePageRefresh],
  computed: {
    ...mapGetters('entry', ['list', 'topic', 'total'])
  },
  methods: {
    ...mapMutations('entry', ['setList']),
    ...mapActions('entry', ['getEntries', 'approveEntry', 'deleteEntry', 'restoreEntry']),
    resetQueryParams() {
      const query = this.$store.state.route.query;
      delete query.search;
      delete query.tab;
      delete query.topicId;
      delete query.id;
      delete this.$query.orderKey;
      delete this.$query.orderValue;
    },
    setDefaultQueryParams() {
      if (this.$query.hasOwnProperty('search')) return;
      if (this.$query.hasOwnProperty('topicId')) return;
      if (this.$query.hasOwnProperty('id')) return;
      if (this.$query.hasOwnProperty('orderKey')) return;
      if (this.$query.hasOwnProperty('orderValue')) return;
      if (!this.$query.hasOwnProperty('tab') || !this.$query.hasOwnProperty('page') || !this.$query.hasOwnProperty('perPage')) {
        const queryParams = { ...this.getPaginationParams, ...this.$query };
        this.$router.replace({ query: { ...queryParams, tab: 'all' } });
      }
    },
    changeRoute(query, { reset, replace }) {
      if (reset) {
        this.resetQueryParams();
      }
      const newPath = { query: { ...this.$query, ...query } };
      const method = replace ? 'replace' : 'push';
      this.$router[method](newPath);
    },
    filterList(type) {
      this.$vs.loading();
      this.getEntries(type).finally(() => {
        this.$vs.loading.close();
      });
    },
    handleTabClick(type) {
      this.changeRoute({ tab: type, page: 1 }, { reset: true });
    },
    handleSearch({ text }) {
      const oldVal = this.$query.search;
      if (text === oldVal) {
        return;
      }
      if (text.length > 0 && text.length < 3) {
        this.$vs.notify({
          text: 'Minumum 3 karakter girmelisiniz.',
          color: 'warning'
        });
      }
      if (text.length === 0) {
        this.changeRoute({ page: 1, tab: 'all' }, { reset: true });
      }
      if (text.length >= 3) {
        this.changeRoute({ search: text }, { reset: true });
      }
    },

    handleAction({ text, successText, action, id }) {
      if (confirm(text)) {
        this.$vs.loading();
        this[action](id)
          .then(() => {
            this.$vs.notify({
              color: 'success',
              text: successText
            });
          })
          .finally(() => {
            this.$vs.loading.close();
          });
      }
    },

    handleRestoreEntry({ id, action }) {
      const text = 'Geri getirmek istediğinize emin misiniz';
      const successText = 'Entry Geri Getirildi.';
      this.handleAction({ text, successText, id, action });
    },

    handleDeleteEntry({ id, action }) {
      const text = 'Silmek istediğinize emin misiniz?';
      const successText = 'Entry Silindi.';
      this.handleAction({ text, successText, id, action });
    },

    handleActions({ action, payload }) {
      const handleName = 'handle' + _.upperFirst(action);
      this[handleName]({ ...payload, action });
    },

    async fetchData() {
      this.setDefaultQueryParams();
      this.setPagination(this.$query);
      await this.filterList(this.$query.tab);
    }
  },
  async created() {
    this.fetchData();
  }
};
</script>

<style>
.tab-btn + .tab-btn {
  margin: 5px;
}
</style>
