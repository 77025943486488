var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "vs-button",
        {
          staticClass: "tab-btn",
          class: { active: _vm.$store.state.route.query.tab === "all" },
          attrs: {
            color: "primary",
            type: _vm.$store.state.route.query.tab === "all" ? "filled" : "line"
          },
          on: {
            click: function($event) {
              return _vm.handleTabClick("all")
            }
          }
        },
        [
          _vm._v("\n    Tüm Diolar\n    "),
          _vm.total && _vm.$query.tab === "all"
            ? _c("span", { staticClass: "font-medium" }, [
                _vm._v("(" + _vm._s(_vm.total) + ")")
              ])
            : _vm._e()
        ]
      ),
      _c(
        "vs-button",
        {
          staticClass: "tab-btn",
          class: { active: _vm.$store.state.route.query.tab === "deleted" },
          attrs: {
            color: "danger",
            type:
              _vm.$store.state.route.query.tab === "deleted" ? "filled" : "line"
          },
          on: {
            click: function($event) {
              return _vm.handleTabClick("deleted")
            }
          }
        },
        [
          _vm._v("\n    Silinenler\n    "),
          _vm.total && _vm.$query.tab === "deleted"
            ? _c("span", { staticClass: "font-medium" }, [
                _vm._v("(" + _vm._s(_vm.total) + ")")
              ])
            : _vm._e()
        ]
      ),
      _c("entry-list", {
        attrs: { list: _vm.list, topic: _vm.topic },
        on: { search: _vm.handleSearch, action: _vm.handleActions }
      }),
      _c(
        "div",
        { staticClass: "con-pagination-table vs-table--pagination" },
        [
          _c("vs-pagination", {
            attrs: { total: _vm.getTotalPages },
            model: {
              value: _vm.page,
              callback: function($$v) {
                _vm.page = $$v
              },
              expression: "page"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }